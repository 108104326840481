import React from "react"
import tw from "twin.macro"

// Components
import Layout from "@components/layout"
import Container from "@components/container"
import { heading1, sectionMd } from "@styles/ui"
import Seo from "@components/seo"
import PrimaryCta from "@components/PrimaryCta"

// Assets
import logo from "@images/logos/xywav-logo.svg"
import { Link } from "gatsby"

const StayConnectedThankYouPage = ({ location }) => {
  return (
    <Layout location={location} headerType="narcolepsy" footerType="narcolepsy">
      <Seo title="Thank You for Signing Up | XYWAV®" location={location} />
      <section css={[sectionMd, tw`bg-lilac`]}>
        <img
          src={logo}
          alt=""
          tw="w-[147px] absolute top-8 -right-10 md:(w-[500px] top-10 right-[-120px]) xl:(w-[631px] top-12 right-[-146px])"
        />
        <Container>
          <h1 css={[heading1, tw`text-merlin pt-7 md:pt-24`]}>
            Thank you for
            <br /> signing up!
          </h1>
          <p tw="mb-4">
            Keep an eye out for helpful information about narcolepsy.
          </p>
          <p>
            Share some additional information about yourself so that{" "}
            <br tw="hidden lg:block" />
            we can send you relevant and helpful information.
          </p>
          <div tw="text-center mt-8 md:(pb-2 text-left)">
            <PrimaryCta
              url={`https://cloud.updates.jazzpharma.com/gettingtoknowyou/?ExternalId=${location?.state?.guid}&TriggerCode=XVNCBR`}
              isExternal={true}
              noInterstitial={true}
            >
              Tell Us More About&nbsp;Yourself
            </PrimaryCta>
          </div>
          <div tw="text-center mt-5 md:(pb-14 text-left)">
            <Link to="/narcolepsy" tw="text-studio font-bold underline">
              Return Home
            </Link>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default StayConnectedThankYouPage
